import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { Helmet } from 'react-helmet'; // Import Helmet for dynamic meta tags
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import '../assets/css/writings.css'; // Import the CSS file

const importAllImages = (r) => {
  let images = {};
  r.keys().map((item) => { images[item.replace('./', '')] = r(item); });
  return images;
};

// Dynamically import all images from the writings folder
const images = importAllImages(require.context('../assets/writings/', true, /\.(png|jpg|jpeg|gif)$/));

const WritingDetail = () => {
  const { folder, slug } = useParams();
  const location = useLocation();
  const { imageUrl, title: pageTitle } = location.state || {};
  const [content, setContent] = useState('');
  const [title, setTitle] = useState('');
  const [date, setDate] = useState('');

  // Define specific OG images for each article
  const articleMetaImages = {
    'deepseek': '/static/media/post-r1.png',
    'on_education': '/static/media/die.png',
    'software_engineering': '/static/media/munch.png',
    'goodness': '/static/media/main.png',
    'eacc': '/static/media/black_and_white.png',
    'PopCulture': '/static/media/image1.jpg'
  };

  // Get the specific meta image for this article
  const metaImage = `${window.location.origin}${articleMetaImages[folder]}`;

  useEffect(() => {
    // Dynamically import the markdown file
    import(`../assets/writings/${folder}/content.md`)
      .then(res => fetch(res.default))
      .then(res => res.text())
      .then(text => {
        const lines = text.split('\n');
        const firstHeader = lines.find(line => line.startsWith('# '));
        // Look for date in italic format (*Date: something*)
        const dateLine = lines.find(line => line.match(/\*Date:.*\*/));

        if (firstHeader) {
          setTitle(firstHeader.replace('# ', ''));
          text = text.replace(firstHeader, ''); // Remove the title line from the markdown content
        }

        if (dateLine) {
          // Extract date without the markdown syntax
          const cleanDate = dateLine.replace(/\*Date:\s*|\*/g, '').trim();
          setDate(cleanDate);
          // Remove the original date line from content
          text = text.replace(dateLine, ''); // Remove the date line from the markdown content
        }

        // Replace image paths in the markdown content
        const processedContent = text.replace(/<img src="([^"]+)" alt="([^"]*)"/g, (match, fileName, altText) => {
          const imagePath = images[`${folder}/${fileName}`];
          return `<img src="${imagePath}" alt="${altText}"`;
        });

        setContent(processedContent);
      })
      .catch(err => console.error('Error loading markdown content:', err));
  }, [folder, metaImage]);

  return (
    <div id="writing-detail" className="content">
      <Helmet>
        <title>{title}</title>
        <meta property="og:type" content="article" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={`An article by Avid Fayaz about ${title}`} />
        <meta property="og:image" content={metaImage} />
        <meta property="og:url" content={`https://www.avidfayaz.com/writings/${folder}/${slug}`} />
        <meta property="og:site_name" content={title} />
      </Helmet>
      <div className="markdown-content">
        <h2>{title}</h2>
        {date && <p className="date"><em>{date}</em></p>} {/* Add class "date" */}
        <div className="writing-content">
          <ReactMarkdown 
            remarkPlugins={[remarkGfm]} 
            rehypePlugins={[rehypeRaw]}
            components={{
              em: ({node, ...props}) => <em {...props} /> // Ensure proper em rendering
            }}
          >
            {content}
          </ReactMarkdown>
        </div>
      </div>
    </div>
  );
};

export default WritingDetail;